<template>
  <div class="app">
    <toubu></toubu>
    <div style="clear: both;" class="">
      <BDhear :num="3"></BDhear>
    </div>
    <div class="app-xt">
      <div class="c-div">
        <div style="overflow: auto;" v-html="xihtml" class="conter">
          <!-- <iframe :src="pdfURL" frameborder="0" width="100%" height="100%"></iframe> -->
          <!-- <div class="heard">
            <div class="title1">声明!</div>
            <div>
              <span class="kg"></span> 因贵方(即“委托保证人”,以下简称“甲方”)作为投标人依法进行招投 标活动，根据招标文件的规定贵方须就投标项目办理一份电子投标保函，本平台
              依法为贵方办理电子投标保函提供网络平台技术服务。
            </div>
            <div>
              <span class="kg"></span> 我方在此郑重声明：根据《中华人民共和国民法总则》、《中华人民共和国 民事诉讼法》、《中华人民共和国电子签名法》、《中华人民共和国电子商务
              法》等法律法规的规定，电子商务当事人订立和履行合同受我国法律保护，贵方 通过本平台办理电子投标保函行为合法有效，贵方应当遵守本平台交易规则及下 列合同条款的约定，遵循自愿、平等、公平、诚信的原则，遵守法律和商业道
              德。因招投标行为的保密性要求，通过本平台办理的电子投标保函全部采取技术 性保密措施，故贵方进行系统操作时不再填写任何具体投标项目信息(主要指项
              目名称、投标人名称、保额、保证期间等信息),而是根据贵方使用电子CA证
              书在该系统进行投标时自动生成的项目串码(该串码具有唯一性)进行关联的， 电子投标保函投保行为完成后系统自动生成，且具有唯一性。
            </div>
            <div>
              <span class="kg"></span>贵方一旦同意投保并缴纳保费即视为贵方认可本平台交易规则，愿意遵守与 保证人的委托保证合同权利义务，保证人不可撤销的就贵方参加该投标项目向受 益人(即“招标人”)承担不可撤销的连带责任保证，直至贵方履行完投标项下
              的权利义务时止。否则，贵方应点击“不同意”,停止投保行为。
            </div>
            <div class="title1">
              投标委托保证合同
            </div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;">
              委托保证人(以下称甲方):中唐工程咨询有限公司
            </div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;">保证人(以下称乙方):河南华诚工程担保有限公司</div>
            <div>
              <span class="kg"></span>鉴于甲方参加投标活动
              <span style="color: red;">南阳城乡一体化示范区白河大道道路工程(机场南六 路-独山大道，月季大道-信臣路)第二标段：南段白河大道(独山大道至机场
                南六路)全过程及保修期监理服务，</span>
              乙方接受甲方的委托，同意为甲方以保证 方式向
              <span style="color: red;">南阳市城乡一体化示范区住房和城乡建设服务中心</span>
              (以下简称“受益
              人”)提供投标担保。甲乙双方经协商一致，订立本合同。
            </div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第 一 条定义</div>
            <div><span class="kg"></span>本合同所称投标担保是指乙方向招标人保证，当甲方未按照招标文件的规定 履行投标人义务时，由乙方代为承担保证责任的行为。</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第二条保证的范围及保证金额</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;"><span class="kg"></span>乙方在甲方发生以下情形时承担保证责任：</div>
            <div><span class="kg"></span>2.1 乙方保证的范围是甲方未按照招标文件的规定履行投标人义务，给招标 人造成的实际损失。</div>
            <div><span class="kg"></span>(1)在招标文件规定的投标有效期内未经招标人许可撤回投标文件。</div>
            <div><span class="kg"></span>(2)中标后因中标人原因未在招标文件规定的时间内与招标人签订《建设工 程施工合同》。</div>
            <div><span class="kg"></span>(3)中标后不能按照招标文件的规定提供履约保证。</div>
            <div><span class="kg"></span>(4)招标文件规定的投标人应支付投标保证金的其他情形。</div>
            <div><span class="kg"></span>2.2 乙方保证的金额为人民币 <span style="color: red;">24000.00 元(大写： 贰万肆仟元整)</span>,
              具体
              数额根据招标文件有关投标保证金具体数额的约定。</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第三条保证的方式及保证期间</div>
            <div><span class="kg"></span>3.1 乙方保证的方式为：连带责任保证。</div>
            <div><span class="kg"></span>3.2 乙方保证的期间为：自保函生效之日起至招标文件规定的投标有效期届 满后30 日止。</div>
            <div><span class="kg"></span>3.3 投标有效期延长的，经乙方书面同意后，保函的保证期间做相应调整。</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第四条承担保证责任的形式</div>
            <div><span class="kg"></span>4.1 乙方根据招标人要求以下列方式之一承担保证责任：</div>
            <div><span class="kg"></span>(1)代甲方支付投标保证金，但最高不超过2.2条约定的保证金额最高额。</div>
            <div><span class="kg"></span>(2)如果招标人选择重新招标，乙方支付重新招标的费用，但支付金额不超
              过本合同第二条约定的保证金额。</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第五条担保费及支付方式</div>
            <div><span class="kg"></span>5.1担保费率根据担保额、担保期限、风险等因素确定。</div>
            <div><span class="kg"></span>5.2甲方一次性支付乙方担保费共计人民币 <span style="color: red;">200.0 元(大写： 贰佰元整元 整)</span> ,保费的具体数额根据本系统自动生成的保费付款信息确定。</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第六条乙方的追偿权</div>
            <div>乙方按照本合同的约定承担了保证责任后，即有权要求甲方在5日内立即归 还乙方代偿的全部款项及乙方实现债权的费用(包括但不限于律师费、诉讼费、
              公告费、保全费、保全担保费、执行费、拍卖费、变卖费、评估费等),甲方另 外自乙方代偿之日起按银行同期利率的四倍支付贷款利息、罚息，并按上述代偿 款项的10%一次性支付违约金。</div>
            <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第七条双方的其他权利义务</div>
            <div><span class="kg"></span>7.1乙方在甲同意并认可本合同、缴费成功且办理完规定的反担保手续后进 行承保，向招标人或系统出具《电子投标保函》。</div>
            <div><span class="kg"></span>7.2甲方如需变更名称、经营范围、注册资金、注册地、主要营业机构所在 地、法定代表人或发生合并、分立、重组等重大经营举措应提前三十日通知乙 方；发生亏损、诉讼等事项应立即通知乙方。</div>
            <div><span class="kg"></span>7.3因招投标行为的保密性要求，通过本平台办理的电子投标保函全部采取 技术性保密措施，故贵方进行系统操作时不再填写任何具体投标项目信息(主要
              指项目名称、投标人名称、保额、保证期间等信息),而是根据贵方使用电子
              CA证书在该系统进行投标时自动生成的项目串码(该串码具有唯一性)进行关 联的，电子投标保函投保行为完成后系统自动生成，且具有唯一性。甲乙双方均 对此表示理解和认可，自愿承担该保密行为项下的责任义务。</div>
              <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第八条争议的解决</div>
              <div>本合同发生争议或纠纷时，甲乙双方当事人可以通过协商解决，协商不成 的，通过第8.1 款约定的方式解决：</div>
              <div><span class="kg"></span>8.1向乙方所在地法院起诉;</div>
              <div style="color:#333;margin-bottom: 8px;font-size: 16px;margin-top: 8px;">第九条合同的生效、变更和解除</div>
              <div><span class="kg"></span>9.1甲方在阅读完本合同条款后选择“同意”按钮进行投标，并根据系统自  动生成的保费付款方式缴纳保费的，保费到账之时即视为投保行为完成并与乙方 签订该《投标委托保证合同》之时，该合同立即生效并对甲乙双方产生法律约束 力!</div>
              <div><span class="kg"></span>9.2 该投保行为为不可撤销法律行为，投保完成后除非因法律原因双方均不 得予以撤销。</div>
          </div> -->
        </div>
        <div class="anniu">
          <div class="btn" @click="next()">下一步</div>
        </div>
      </div>
    </div>
    <dinad class="qingchu"></dinad>
  </div>
</template>

<script>
import dinad from '../../components/dinad.vue'
import toubu from '../../components/toubu.vue'
// import ''
import $ from 'jquery'
// import jquery from 'jquery'
import {
  getUserChannels,
  createcontract,
  applyprogress,
  yulan
} from '@/api/user'
import axios from 'axios'
import BDhear from '../../components/BDhear.vue'
// import "./js/modernizr.js";
// import "./js/main"
export default {
  components: {
    dinad,
    BDhear,
    toubu
  },
  data() {
    return {
      canvas: null,
      pdfURL: '',
      xihtml: ''
    }
  },
  async created() {
    const { data } = await yulan(sessionStorage.getItem('applyno'))
    console.log(data)
    this.xihtml = data.data.html

    sessionStorage.setItem('userurl', data.data.url)
    sessionStorage.setItem('xieyiId', data.data.xieyid)

    // this.pdfURL='https://www.hnzyxsbh.com/tripartite/mobile/agreement/yulan'
    // this.pdfURL='http://192.168.0.192:18080?applyno='+ sessionStorage.getItem("applyno")
  },
  mounted() {},
  methods: {
    next() {
      this.$router.push('/gaizhang')
      //      if(sessionStorage.getItem("unify_code")=='91410296MA46T65N4E'){
      //       this.$router.push("/project");
      //      }else{
      // this.$router.push('/gaizhang');
      //      }
    }
  }
}
</script>

<style scoped>
html {
  width: 100vw;
  width: 100%;
  background: #eeee !important;
}

.app {
  height: 100%;
}

.app-xt {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.c-div {
  width: 80%;
  height: 100%;
  margin: 1vh auto;
  box-sizing: border-box;
  background-color: #f5f6f8;
  padding: 10px 0 10px;
}

.conter {
  width: 85%;
  height: 90%;
  margin: 0 auto;
}

.heard {
  width: 100%;

  position: relative;
}
.heard div {
  margin-bottom: 5px;
  line-height: 20px;
}

.list1 {
  width: 100%;
  height: 70px;
  background-color: #e0e9f7;
}

.bottom {
  width: 25%;
  text-align: center;
  float: left;
}

.bottom p {
  height: 35px;
  line-height: 35px;
}

.anniu {
  width: 100%;
}

.btn {
  width: 80px;
  height: 20px;
  padding: 10px;
  border: 1px solid #4d8efc;
  border-radius: 40px;
  margin: 15px auto;
  text-align: center;
  line-height: 20px;
}
.title1 {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 15px 0;
}
.kg {
  display: inline-block;
  width: 32px;
}
</style>
